import { Box, Container, Heading, Image, Stack, Textarea, Text, Input, SimpleGrid, Button, Flex, HStack, Collapse, IconButton, Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, useDisclosure,Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BiSlider } from 'react-icons/bi';
import axios from 'axios';
import moment from 'react-moment';
import Navbar from './Navbar';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const AddCampaign = () => {
  const [resize, setResize] = React.useState('vertical')
  const [temp, setTemp] = useState([]);
  const [mode, setMod] = useState([]);
  const [users, setUsers] = useState([]);
  const [collapse, setCollapse] = React.useState(true);
  const navigate = useNavigate();

  const [alertt, setAlertt] = useState();

  const [checkArray, setcheckArray] = useState([]);


  const [Name, setName] = useState('');
  const [Date, setDate] = useState('');
  const [Time, setTime] = useState('');
  const [Mode, setMode] = useState('');
  const [Group, setGroup] = useState('');
  const [Dlt, setDlt] = useState('');
  const [Dltt, setDltt] = useState('');
  const [Status, setStatus] = useState('');
  const [com_id, setId] = useState('');


  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/group.php');
    setUsers(await response.json());
  }



  useEffect(() => {
    getUsers();
  }, []);

  const handelGroupp = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    console.log(value, checked);

    if (checked) {
      setcheckArray([
        ...checkArray, value
      ])
    } else {
      setcheckArray(checkArray.filter((e) => (e !== value)))
    }
    //let tempUser = users.map( (user) => user.Customer_Group === namee ? {...user, isChecked : checked} : user);

  }

  const handelName = (e) => {
    setName(e.target.value)
  }

  const handelDate = (e) => {
    setDate(e.target.value)
  }

  const handelTime = (e) => {
    setTime(e.target.value)
  }

  const handelMode = (e) => {
    setMode(e.target.value)
  }

  const handelDltt = (e) => {
    setDltt(e.target.value)
  }

  const handelDlt = (e) => {
    setDltt(e.target.value)
  }

  const handelStatus = (e) => {
    setStatus(e.target.value)
  }




  const handleSubmit = (event) => {


    event.preventDefault();

    let fData = new FormData();
    fData.append('Campaign_name', Name);
    fData.append('date', Date);
    fData.append('time', Time);
    fData.append('mode', Mode);
    fData.append('group', checkArray);
    fData.append('dlt', Dltt);
    fData.append('status', Status);

    axios.post('https://radhamadhavseva.com/api/post_comp.php?id=sav', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'Campaign Successfully Scheduled') {
          //alert(result.data)
          navigate("/CampaignList");
          ///alert(result.data)
        } else {
          //alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };


  const getTemplate = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/smsformat_view.php');
    setTemp(await response.json());

  }

  useEffect(() => {
    getTemplate();
  }, []);


  const getMode = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/mode.php?edit=edit');
    setMod(await response.json());

  }

  useEffect(() => {
    getMode();
  }, []);

  const moment = require('moment');

  let now = moment();
  const dt = now.format("YYYY-MM-DD");
  const tm = now.format("H:m");


  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

                      Add Campaign</Heading>

            <div class="container-fluid border">
              <div class="row justify-content-center">

                {alertt === 'Campaign Successfully Scheduled' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='success'>
                      <AlertIcon />
                      Campaign Successfully Scheduled
                    </Alert>
                  </Stack>
                }

                {alertt === 'not save' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='error'>
                      <AlertIcon />
                      Campaign Not  Scheduled !
                    </Alert>
                  </Stack>
                }


                {alertt === 'Campaign Already saved' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='error'>
                      <AlertIcon />
                      Campaign Already saved !
                    </Alert>
                  </Stack>
                }


                {alertt === 'please select group' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='error'>
                      <AlertIcon />
                      select group * !
                    </Alert>
                  </Stack>
                }





                <form class="col-lg-8 mb-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4} marginBottom={'40px'}>

                   
                    <Input
                      placeholder={'Campaign name'}
                      type={'text'}
                     
                      onChange={handelName}
                     

                      top={2}
                      required
                      focusBorderColor={'purple.500'}
                    />

                    <Input
                      placeholder={'Date'}
                      type={'date'}
                     
                      onChange={handelDate}
                      min={dt}
                     
                      top={2}

                      required
                      focusBorderColor={'purple.500'}
                    />

                    <Input
                      placeholder={'Time Slot'}
                      type={'time'}
                     
                      onChange={handelTime}
                      min={dt === Date ? (tm) : ('')}
                     
                      top={2}

                      required
                      focusBorderColor={'purple.500'}
                    />

                    <Select placeholder='Select Mode'
                      onChange={handelMode}
                     
                      top={2}

                      required
                      focusBorderColor={'purple.500'}>
                      {

                        mode.map((curElem) => {
                          return (
                            <option value={curElem.type}>{curElem.type}</option>


                          )
                        })
                      }



                    </Select>





                    <Button onClick={onToggle}>Select Group</Button>
                    <Collapse in={isOpen} animateOpacity>
                      <Box
                        p='40px'
                        color='white'
                        mt='4'
                        bg='teal.500'
                        rounded='md'
                        shadow='md'
                      >
                        <HStack>
                          {
                            users.map((curElem, index) => {
                              return (
                                <>
                                 <Checkbox  value={curElem.Customer_Group} onChange={handelGroupp}>{curElem.Customer_Group}</Checkbox>
                                  
                                </>
                              )
                            })}

                        </HStack>
                      </Box>
                    </Collapse>





                    <Select placeholder='Select Template'
                      onChange={handelDltt}
                     
                      top={2}

                      required
                      focusBorderColor={'purple.500'}>
                      {

                        temp.map((curElem) => {
                          return (
                            <option value={curElem.format}>{curElem.format}</option>


                          )
                        })
                      }
                    </Select>


                    <Textarea
                      placeholder={'DLT No.'}
                      type={'text'}
                     
                      onChange={handelDlt}
                     
                      top={2}
                      value={Dltt}
                      required
                      focusBorderColor={'purple.500'}
                    />





                  <Center>
                    <Button bg={'#9f1d21'} w={40} color={'white'} type={'submit'}>
                      Submit
                    </Button>
                    </Center>

                  </SimpleGrid>
                 
                </form>



              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};


export default AddCampaign;
