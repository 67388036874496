import { Box, Container, Heading, Image, Stack, Text, SimpleGrid, Button, Flex, HStack, collapse, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import Navbar from './Navbar';
import '../Styles/main.css';
const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const Home = () => {

  const [users, setUsers] = useState([]);
  const [Campaign, setCampaign] = useState([]);
  const [employe, setEmploye] = useState([]);

  const [collapse, setCollapse] = React.useState(true);
  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/view.php');
    setUsers(await response.json());

  }


  useEffect(() => {
    getUsers();
  }, []);


  const getCampaignrecords = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/comp_view.php');
    setCampaign(await response.json());

  }


  useEffect(() => {
    getCampaignrecords();
  }, []);



  const getEmployerecords = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/employe_view.php');
    setEmploye(await response.json());

  }


  useEffect(() => {
    getEmployerecords();
  }, []);


  return (
    <>
      <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
            <div class="container-fluid mt-4">
              <div class="row">
                <div class="col mt-2">
                  <Card backgroundColor={'#9f1d21'}>
                    <CardHeader>
                      <Heading size='md' color={'white'}>Total Data Record</Heading>
                    </CardHeader>
                    <CardBody>
                      <Text fontWeight={'bold'} color={'white'}>{users.length}</Text>
                    </CardBody>
                    <CardFooter backgroundColor={'white'}>
                      <Link to={'/View'}> <Button>View here</Button></Link>
                    </CardFooter>
                  </Card>
                </div>
                <div class="col mt-2">
                  <Card backgroundColor={'#9f1d21'}>
                    <CardHeader>
                      <Heading size='md' color={'white'}>Total Campaign Record</Heading>
                    </CardHeader>
                    <CardBody>
                      <Text fontWeight={'bold'} color={'white'}>{Campaign.length}</Text>
                    </CardBody>
                    <CardFooter backgroundColor={'white'}>
                      <Link to={'/CampaignList'}> <Button>View here</Button></Link>
                    </CardFooter>
                  </Card>
                </div>
                <div class="col mt-2">
                  <Card backgroundColor={'#9f1d21'}>
                    <CardHeader>
                      <Heading size='md' color={'white'}>Total Employe Record</Heading>
                    </CardHeader>
                    <CardBody>
                      <Text fontWeight={'bold'} color={'white'}>{employe.length}</Text>
                    </CardBody>
                    <CardFooter backgroundColor={'white'}>
                      <Link to={'/EmployeList'}> <Button>View here</Button></Link>
                    </CardFooter>
                  </Card>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};


export default Home;
