import { Box, Container, Heading, Image, Stack, Textarea, Text, Input, SimpleGrid, Button,Flex,HStack,collapse,IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import Navbar from './Navbar';
const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const Contact = () => {
  const [resize, setResize] = React.useState('vertical')
  const [users, setUsers] = useState([]);
  const [collapse, setCollapse] = React.useState(true);
  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/view.php');
    setUsers(await response.json());

  }



  useEffect(() => {
    getUsers();
  },[]);

  return (
    <>
     <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

                      Contact Us</Heading>
            <div class="container-fluid border">
              <div class="row">
              
              <form  mt={'10px'} >
<SimpleGrid spacing={4} mt={4} marginBottom={5}>
   <Input
                  placeholder={'Email Id'}
                  type={'email'}
                  h={9}
                  top={2}
                  required
                  focusBorderColor={'#9f1d21'}
                />
<Textarea
     
        placeholder='Description'
        size='sm'
        resize={resize}
      />

      <Center>

<Button bg={'#9f1d21'} color={'white'} type={'submit'} top={1}>
                  submit
                </Button>
                </Center>
      </SimpleGrid>
      </form>
       
    
                </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};


export default Contact;
