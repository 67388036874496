import { Box, Container, Heading, Image, Stack, Text, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter,Center } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import Navbar from './Navbar';
import axios from 'axios';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'


const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const  EmployeReg = () => {

  const [users, setUsers] = useState([]);
  const [collapse, setCollapse] = React.useState(true);

  const [email, setEmail] = useState([]);
  const [pass, setPassword] = useState([]);
  const [name, setName] = useState([]);
  const [desig, setDesig] = useState([]);
  
  const [eid, setEid] = useState([]);

  const [alertt,  setAlertt] = useState();
  const navigate = useNavigate();

  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/view.php');
    setUsers(await response.json());

  }


  const handelName = (e) => {
    setName(e.target.value)
  }

  const handelEid = (e) => {
    setEid(e.target.value)
  }

  const handelDesig = (e) => {
    setDesig(e.target.value)
  }

  


  const handelEmail = (e) => {
    setEmail(e.target.value)
  }

  const handelPass = (e) => {
    setPassword(e.target.value)
  }


  const handleSubmit = (event) => {
    
    //Prevent page reload
    event.preventDefault();
    let fData = new FormData();
    fData.append('name', name);
    fData.append('email', email);
    fData.append('pass', pass);
    fData.append('eid', eid);
    fData.append('desig', desig);
    fData.append('pass', pass);
   

    axios.post('https://radhamadhavseva.com/api/employe_reg.php', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'yes') {

          setTimeout(() => {
            
                navigate("/EmployeList");
            
          }, 1000);
      
          ///alert(result.data)
        } else {
         // alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };



  useEffect(() => {
    getUsers();
  },[]);

  return (
    <>
   <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

Employe Registration</Heading>
            <div class="container-fluid  border">
              <div class="row justify-content-center">
     
  {alertt==='yes' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='success'>
          <AlertIcon />
          Employe Successfully Registered
        </Alert>
      </Stack>
      }

{alertt==='no' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='error'>
          <AlertIcon />
          Employe Not saved !
        </Alert>
      </Stack>
      }

{alertt==='Employe Already saved' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='error'>
          <AlertIcon />
          Employe Already saved !
        </Alert>
      </Stack>
      }

              <form class="col-lg-8 mb-3 mt-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4} marginBottom={'40px'}>

                   
           
  <Input
                  placeholder={'Employee Name'}
                  type={'text'}
                 
                  onChange={handelName}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  
  <Input
                  placeholder={'Employee id'}
                  type={'text'}
                 
                  onChange={handelEid}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  
  <Input
                  placeholder={'Designation'}
                  type={'text'}
                 
                  onChange={handelDesig}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  


  <Input
                  placeholder={'Email'}
                  type={'email'}
                 
                  onChange={handelEmail}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  
  <Input
                  placeholder={'Password'}
                  type={'text'}
                 
                  onChange={handelPass}
                  required
                  focusBorderColor={'#9f1d21'}
                />

<Center>
                    <Button bg={'#9f1d21'} w={40} color={'white'} type={'submit'}>
                      Submit
                    </Button>
                    </Center>

  
  
</SimpleGrid>

                </form>

              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};


export default  EmployeReg;
