import { Box, Container, Heading, Image, Stack, Text, Collapse, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack, InputGroup, InputLeftAddon, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, useDisclosure, Textarea } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom';
import { BiSlider, BiEdit, BiTrash, BiBlock, BiCheckSquare } from 'react-icons/bi';
import axios from 'axios';
import Navbar from './Navbar';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Center
} from '@chakra-ui/react'


const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const MsgCenter = () => {

  const [users, setUsers] = useState([]);
  const [collapse, setCollapse] = React.useState(true);

  const [format, setFormat] = useState([]);

  const [fid, setFid] = useState([]);

  const [alertt, setAlertt] = useState();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/msgcenter_view.php');
    setUsers(await response.json());

  }


  const handelFormat = (e) => {
    setFormat(e.target.value)
  }



  const handleSubmit = (event) => {

    //Prevent page reload
    event.preventDefault();
    let fData = new FormData();
    fData.append('for', format);

    axios.post('https://radhamadhavseva.com/api/msgcenter.php?sav=sav', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'yes') {

          setTimeout(() => {

            window.location.reload(true);

          }, 1000);

          ///alert(result.data)
        } else {
          // alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };



  useEffect(() => {
    getUsers();
  }, []);

  const { isOpen, onToggle } = useDisclosure()

  function Delete(id) {
    axios.post('https://radhamadhavseva.com/api/msgcenter.php?del=' + id).then(result => {
      if (result.data === 'yes') {
        navigate('/MsgCenter');
        window.location.reload(true);
      } else {
        alert(result.data)
        navigate('/AddSmsFormat');
      }
    })
  }


  return (
    <>
      <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>



          <div class="col-lg-10 top-0  end-0">
          <TableContainer w={'full'} >

<Table marginBottom={'12px'} w={'full'} border="2px" borderColor={"#9f1d21"} backgroundColor={'#9f1d21'}>

  <Thead>



    <Tr marginRight={5}>
      
      <Td>
        <Heading fontSize={'18px'} color={'white'}>Message Center list</Heading>
      </Td>
      <Td>

        <Input placeholder='Search' w={'500px'} onChange={(e) => setQuery(e.target.value)} size='md' border="2px" borderColor={"#9f1d21"} borderRadius={'105px'} backgroundColor={'white'} />

      </Td>
      <Td>
        <Button bg={'white'} color={'black'} onClick={onToggle}>+</Button>
      </Td>
    </Tr>

  </Thead>
</Table>

</TableContainer>
            <div class="container-fluid border">
              <div class="row">


               

                  <Collapse in={isOpen} animateOpacity>
                    <form class="col-lg-8 mb-3 mt-3" onSubmit={handleSubmit}>
                      <SimpleGrid spacing={4}>
<div class="row">
  <div class="col-lg-8">
                        <InputGroup mt={4}>
                          <InputLeftAddon children='+91' />
                          <Input
                            required
                            focusBorderColor={'#9f1d21'}
                            onChange={handelFormat}
                            color={'black'}
                            size='md'
                            type='tel' placeholder='phone number' />
                        </InputGroup>
                        </div>

 <div class="col-lg-4">

                        <Button bg={'#9f1d21'} color={'white'} type={'submit'}  mt={4}>
                          Submit
                        </Button>
                       </div>
                       </div>
                      </SimpleGrid>
                    </form>

                    {alertt === 'yes' &&
                      <Stack spacing={3} mt={'40px'} >
                        <Alert status='success'>
                          <AlertIcon />
                          Number Successfully Registered
                        </Alert>
                      </Stack>
                    }

                    {alertt === 'no' &&
                      <Stack spacing={3} mt={'40px'} >
                        <Alert status='error'>
                          <AlertIcon />
                          Number Not saved !
                        </Alert>
                      </Stack>
                    }

                    {alertt === 'Format Already saved' &&
                      <Stack spacing={3} mt={'40px'} >
                        <Alert status='error'>
                          <AlertIcon />
                          NumberAlready saved !
                        </Alert>
                      </Stack>
                    }



                  </Collapse>

                 

                        <TableContainer w={'full'} marginBottom={9}>
                          <Table size='sm'  mt={3} variant='striped' colorScheme='gray' border='2px' borderColor='#9f1d21'>

                            <Thead backgroundColor={'#9f1d21'}>
                              <Tr>
                                <Th color={'white'}>S No.</Th>
                                <Th color={'white'}>Mobile no.</Th>


                                <Th color={'white'}>Action</Th>


                              </Tr>
                            </Thead>
                            <Tbody>
                              {

                                users.filter(user => user.phone.toLowerCase().includes(query)).map((curElem, index) => {
                                  return (

                                    <Tr key={index}>
                                      <Td>{index + 1}</Td>
                                      <Td>{curElem.phone}</Td>
                                      <Td>
                                        <HStack>
                                          <Button variant='solid' size="sm" bg='white' onClick={() => navigate(`/EditMsgCenter/${curElem.id}`)}>
                                            <BiEdit fontSize={20} />
                                          </Button>

                                          <Link variant='solid' colorScheme='green' onClick={() => Delete(curElem.id)}>
                                            <BiTrash fontSize={20} />
                                          </Link>
                                        </HStack>
                                      </Td>

                                    </Tr>

                                  )
                                })
                              }
                            </Tbody>

                          </Table>
                        </TableContainer>
                   

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};


export default MsgCenter;
