import { Button, Heading, Stack, Text, VStack, HStack, Link, Textarea, Hide, Select } from '@chakra-ui/react';
import { Box, Image, SimpleGrid, Flex, collapse, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Navbar from './Navbar';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Container, Input, Center
} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, useDisclosure
} from '@chakra-ui/react'
import { getSuggestedQuery } from '@testing-library/react';
import secureLocalStorage from "react-secure-storage";

import { BiSlider, BiEdit, BiTrash, BiBookContent, BiListUl } from 'react-icons/bi';


const CampaignLogs = () => {

    const [collapse, setCollapse] = React.useState(true);
    const [users, setUsers] = useState([]);
    const [group, setGroup] = useState([]);
    const [query, setQuery] = useState("");
    const [cmtid, setComentid] = useState([]);
    const [comment, setComent] = useState([]);
    const [alertt, setAlertt] = useState();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 25;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const nPage = Math.ceil(users.length / recordsPerPage);



    const getUsers = async () => {
        const response = await fetch('https://radhamadhavseva.com/api/campaign_logs.php');
        setUsers(await response.json());
    }



    useEffect(() => {
        getUsers();
    }, []);


    const getGroup = async () => {
        const response = await fetch('https://radhamadhavseva.com/api/group.php');
        setGroup(await response.json());
    }



    useEffect(() => {
        getGroup();
    }, []);







    const handelComent = (e) => {
        setComent(e.target.value)

    }

    const { isOpen, onOpen, onClose } = useDisclosure();
    // console.log(users.filter(user=>user.name.toLowerCase().includes("e")));

    const comtBy = secureLocalStorage.getItem("tokenn");

    const type = secureLocalStorage.getItem("type");

    const handelQuery = (e) => {
        setQuery(e.target.value)
    }

    const handleSubmit = (event) => {

        //Prevent page reload
        event.preventDefault();
        let fData = new FormData();
        fData.append('cid', cmtid);
        fData.append('comment', comment);
        fData.append('commentby', comtBy);



        axios.post('https://radhamadhavseva.com/api/add_comt.php', fData)

            .then(result => {
                console.log(result.data)
                const cHeck = result.data;
                setAlertt(result.data);
                if (cHeck === 'yes') {

                    setTimeout(() => {

                        window.location.reload(true);

                    }, 1000);

                } else {
                    // alert(result.data)
                }


            })

            .catch(error => {
                alert('not success')
                console.log(error)
            })

    };


    function prePage() {

        if (currentPage !== firstIndex) {
            if (firstIndex === 0) {
                //alert('h')
            } else {
                setCurrentPage(currentPage - 1)
            }
        }

    }

    function nextPage() {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1)
        }

    }



    return (
        <>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


                        <div class='sticky-left position-fixed  sidebar'>

                            <Header />


                        </div>

                    </div>
                    <div class="col-lg-10 top-0  end-0">
                        <div class="container-fluid">

                            <div class="row">

                                <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                                    <ModalOverlay />
                                    <ModalContent>
                                        <form onSubmit={handleSubmit}>
                                            <ModalHeader>Add Comment</ModalHeader>
                                            <ModalCloseButton />
                                            <ModalBody>

                                                <Hide>

                                                    <Input
                                                        placeholder={'Campaign name'}
                                                        type={'text'}
                                                        h={9}

                                                        top={2}
                                                        value={cmtid}
                                                        required
                                                        focusBorderColor={'#9f1d21'}
                                                    />

                                                </Hide>

                                                <Textarea
                                                    placeholder={'Add comments'}
                                                    type={'text'}
                                                    h={9}
                                                    onChange={handelComent}

                                                    top={2}
                                                    required
                                                    focusBorderColor={'#9f1d21'}
                                                />
                                            </ModalBody>

                                            <ModalFooter>
                                                <Button bg={'#9f1d21'} color={'white'} type={'submit'}>
                                                    Submit
                                                </Button>
                                            </ModalFooter>
                                        </form>


                                        {alertt === 'yes' &&
                                            <Stack spacing={3} mt={'40px'} >
                                                <Alert status='success'>
                                                    <AlertIcon />
                                                    Comment Saved
                                                </Alert>
                                            </Stack>
                                        }

                                        {alertt === 'no' &&
                                            <Stack spacing={3} mt={'40px'} >
                                                <Alert status='error'>
                                                    <AlertIcon />
                                                    Comment Not saved !
                                                </Alert>
                                            </Stack>
                                        }

                                    </ModalContent>
                                </Modal>



                                <Box
                                    overflowY="auto"

                                    css={{
                                        '&::-webkit-scrollbar': {
                                            width: '4px',

                                        },
                                        '&::-webkit-scrollbar-track': {
                                            width: '6px',

                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            borderRadius: '24px',

                                        }

                                    }}

                                >



                                    <Heading
                                        fontSize={'17px'}
                                        backgroundColor='#9f1d21'
                                        color={'black'}
                                        p={2}

                                    >
                                        <div class="row">
                                            <div class="col-lg-6 col-sm-12 col-md-12">

                                                <Select backgroundColor='white' onChange={handelQuery} placeholder='Search by Group'>
                                                    {

                                                        group.map((curElem, index) => {
                                                            return (

                                                                <option value={curElem.Customer_Group} >{curElem.Customer_Group}</option>


                                                            )
                                                        })
                                                    }

                                                </Select>
                                            </div>

                                        </div>
                                    </Heading>
                                    <Stack direction={['column', 'row']} p={'0px'}  >




                                        <TableContainer w={'full'} mt={2}>
                                            <Table size='sm' variant='striped' colorScheme='gray' border='2px' borderColor='#9f1d21'>

                                                <Thead backgroundColor={'#9f1d21'}>
                                                    <Tr>
                                                        <Th color={'white'}>ID</Th>
                                                        <Th color={'white'}>Campaign Name</Th>
                                                        <Th color={'white'}>Customer Group</Th>
                                                        <Th color={'white'}>Date Time</Th>


                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {

                                                        users.filter(user =>
                                                            user.cust_group.toLowerCase().includes(query)
                                                        ).slice(!query ? firstIndex : 0, !query ? lastIndex : 25).map((curElem, index) => {
                                                            return (

                                                                <Tr>
                                                                    <Td fontSize={12}>{index + 1}</Td>
                                                                    <Td fontSize={12}>{curElem.campaign_name}</Td>
                                                                    <Td fontSize={12}>{curElem.cust_group}</Td>
                                                                    <Td fontSize={12}>{curElem.datetime}</Td>

                                                                </Tr>

                                                            )
                                                        })
                                                    }
                                                </Tbody>

                                            </Table>
                                            <Box float={'right'} marginBottom={3} w={'162px'} mt={3}>

                                                <Button backgroundColor={'#9f1d21'} marginLeft={5} variant={'ghost'} colorScheme={'white'} onClick={prePage} size={'sm'} color={'white'}>Prev</Button>
                                                <Button backgroundColor={'#9f1d21'} size={'sm'} variant={'ghost'} colorScheme={'white'} marginLeft={5} onClick={nextPage} color={'white'}>Next</Button>



                                            </Box>
                                        </TableContainer>
                                    </Stack>



                                </Box>


                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
};





export default CampaignLogs