import { Box, Container, Heading, Image, Stack, Text, Collapse, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, useDisclosure, Textarea,Input } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import Navbar from './Navbar';
import axios from 'axios';

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Center
} from '@chakra-ui/react'
import { Label } from '@mui/icons-material';


const headingOptions = {
    pos: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    textTransform: 'uppercase',
    p: '4',
    size: '4xl',
};

const ModeSet = () => {

    const [users, setUsers] = useState([]);
    const [collapse, setCollapse] = React.useState(true);

    const [format, setFormat] = useState([]);

    const [fid, setFid] = useState([]);

    const [alertt, setAlertt] = useState();
    const navigate = useNavigate();


    const [E, setData_E] = useState([]);
    const [W, setData_W] = useState([]);
    const [S, setData_S] = useState([]);

    const [SE, setData_SE] = useState([]);
    const [SW, setData_SW] = useState([]);
    const [SS, setData_SS] = useState([]);

    const [idd, setData_ID] = useState([]);

    const [userss, setPosts] = useState([]);
    const apiEndPoint = 'https://radhamadhavseva.com/api/mode.php?edit=1';

    useEffect(() => {
        const getPosts = async () => {
            const { data: res } = await axios.get(apiEndPoint);
            setPosts(res);
            setData_ID(res[0].id);
            setData_E(res[0].type);
            setData_W(res[1].type);
            setData_S(res[2].type);

            setData_SE(res[0].status);
            setData_SW(res[1].status);
            setData_SS(res[2].status);
        };
        getPosts();

    }, []);


    const handelFormat = (e) => {
        setFormat(e.target.value)
        setData_E(e.target.value)
    }




    const handleSubmit = (event) => {

        //Prevent page reload
        event.preventDefault();
        let fData = new FormData();
        fData.append('for', SE);


        axios.post('https://radhamadhavseva.com/api/email.php?updt=updt', fData)

            .then(result => {
                console.log(result.data)
                const cHeck = result.data;
                setAlertt(result.data);
                if (cHeck === 'yes') {

                    setTimeout(() => {

                        navigate('/EmailSet');
                        window.location.reload(true);

                    }, 1000);

                    ///alert(result.data)
                } else {
                    // alert(result.data)
                }


            })

            .catch(error => {
                alert('not success')
                console.log(error)
            })

    };

    // const [agreement, setAgreement] = useState([]);
    // const handleChange = (event) => {
    //     setAgreement(event.target.checked);

    //     axios.post('https://radhamadhavseva.com/api/mode.php?typea='+agreement).then(result => {
    //         // if(result.data==='active'){
    //         //   navigate('/CampaignList');
    //          // window.location.reload(true);
    //         // }else{
    //        // alert(result.data)
    //         // navigate('/CampaignList');
    //         // }
    //       })
    //   }


    ///alert(SS)

    function Aa(id) {

       

        axios.post('https://radhamadhavseva.com/api/mode.php?typea=' + id).then(result => {
            // if(result.data==='active'){
            //   navigate('/CampaignList');
            window.location.reload(true);
            // }else{
            // alert(result.data)
            // navigate('/CampaignList');
            // }
        })

    }


    function Ab(id) {



        axios.post('https://radhamadhavseva.com/api/mode.php?typeb=' + id).then(result => {
            // if(result.data==='active'){
            //   navigate('/CampaignList');
            window.location.reload(true);
            // }else{
            // alert(result.data)
            // navigate('/CampaignList');
            // }
        })


    }


    function Ac(id) {



        axios.post('https://radhamadhavseva.com/api/mode.php?typec=' + id).then(result => {
            // if(result.data==='active'){
            //   navigate('/CampaignList');
            window.location.reload(true);
            // }else{
            // alert(result.data)
            // navigate('/CampaignList');
            // }
        })


    }



    React.createElement('input',{type: 'checkbox', defaultChecked: true});

    return (
        <>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


                        <div class='sticky-left position-fixed  sidebar'>

                            <Header />


                        </div>

                    </div>
                    <div class="col-lg-10 top-0  end-0">
                        <div class="container-fluid mt-4">
                            <div class="row">
                                <Heading
                                    fontSize={'17px'}
                                    backgroundColor='#9f1d21'
                                    color={'white'}
                                    p={2}

                                >

                                    Edit Modes</Heading>

                                <Box
                                    p='20px'
                                    mt='1'
                                    rounded='md'
                                    shadow='md'
                                >
                                    <form class="col-lg-8 mb-3" onSubmit={handleSubmit}>


                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-4 col-sm-12">
                                                    {SE > 0 ? (
                                                        <>
                                                        
                                                        <Checkbox  value="0" onChange={() => Aa(0)}     isChecked>Email</Checkbox>
                                                           
                                                        </>
                                                    ) : (
                                                        <>

                                                         <Checkbox  value="1" onChange={() => Aa(1)} >Email</Checkbox>
                                                      
                                                          
                                                        </>
                                                    )}
                                                </div>
                                                <div class="col-lg-4 col-sm-12">
                                                    {SW > 0 ? (
                                                        <>
                                                           <Checkbox  value="0" onChange={() => Ab(0)}     isChecked>What's App</Checkbox>
                                                          
                                                        </>
                                                    ) : (
                                                        <>
                                                        <Checkbox  value="1"  onChange={() => Ab(1)} >What's App</Checkbox>
                                                          
                                                        </>
                                                    )}
                                                </div>
                                                <div class="col-lg-4 col-sm-12">
                                                    {SS > 0 ? (
                                                        <>
                                                        <Checkbox  value="0"   onChange={() => Ac(0)}    isChecked>SMS</Checkbox>
                                                           
                                                        </>
                                                    ) : (
                                                        <>
                                                         <Checkbox  value="1"  onChange={() => Ac(1)}>SMS</Checkbox>
                                                            
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


















                                    </form>

                                    {alertt === 'yes' &&
                                        <Stack spacing={3} mt={'40px'} >
                                            <Alert status='success'>
                                                <AlertIcon />
                                                Updated Successfully
                                            </Alert>
                                        </Stack>
                                    }

                                    {alertt === 'no' &&
                                        <Stack spacing={3} mt={'40px'} >
                                            <Alert status='error'>
                                                <AlertIcon />
                                                Not Updated !
                                            </Alert>
                                        </Stack>
                                    }



                                </Box>



                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};





export default ModeSet;