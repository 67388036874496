import { Box, Container, Heading, Image, Stack, Text, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack, Input, Hide } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { InputLeftAddon, InputGroup } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import axios from 'axios';
import Navbar from './Navbar';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer
} from '@chakra-ui/react'

const headingOptions = {
    pos: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    textTransform: 'uppercase',
    p: '4',
    size: '4xl',
};

const ViewCustomer = () => {

    const [users, setUsers] = useState([]);
    const [collapse, setCollapse] = React.useState(false);
    const { id } = useParams();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [company, setCompany] = useState('');
    const [designation, setDesignation] = useState('');
    const [salary, setSalary] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [group, setGroup] = useState('');
    const [location, setLocation] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [leadtype, setLeadtype] = useState('');

    const [alertt, setAlertt] = useState();



    const [a, setData_A] = useState([]);
    const [b, setData_B] = useState([]);
    const [c, setData_C] = useState([]);
    const [d, setData_D] = useState([]);
    const [e, setData_E] = useState([]);
   
    const [j, setData_J] = useState([]);
    const [k, setData_K] = useState([]);
  
    const [m, setData_M] = useState([]);
    const [n, setData_N] = useState([]);
    const [o, setData_O] = useState([]);
    const [p, setData_P] = useState([]);
  

    const [idd, setData_ID] = useState([]);

    const [userss, setPosts] = useState([]);
    const apiEndPoint = 'https://radhamadhavseva.com/api/edit_view.php?id=' + id;

    useEffect(() => {
        const getPosts = async () => {
            const { data: res } = await axios.get(apiEndPoint);
            setPosts(res);
            setData_ID(res[0].id);
            setData_A(res[0].Customer_Name);
            setData_B(res[0].Mobile);
            setData_C(res[0].Email);
            setData_D(res[0].Company_Name);
            setData_E(res[0].Landline);
            
            setData_J(res[0].Customer_Group);
            setData_K(res[0].Customer_Description);
         
            setData_M(res[0].Address);
            setData_N(res[0].City);
            setData_O(res[0].Area);
            setData_P(res[0].Zip_code);
           
        };
        getPosts();

    }, []);



    const getComt = async () => {
        const response = await fetch('https://radhamadhavseva.com/api/comment_view.php?id=' + id);
        setPosts(await response.json());
    }



    useEffect(() => {
        getComt();
    }, []);

   



    return (
        <>
<Navbar />
<div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
            <div class="container-fluid mt-4">
              <div class="row">
                
            

        


                        <Container maxW={'container.xl'} h={'100vh'} p={'3'}>
                            <TableContainer>
                                <Table size='sm' variant='striped' colorScheme='gray' border='2px' borderColor='#9f1d21'>
                                    
                                    <Thead backgroundColor={'#9f1d21'}>
                                        <Tr>
                                            <Th color={'white'}>Heading</Th>
                                            <Th color={'white'}>Detail</Th>
                                            <Th color={'white'}>Heading</Th>
                                            <Th color={'white'}>Detail</Th>

                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td fontWeight={'bold'}>ID</Td>
                                            <Td>ISKBH0{idd}</Td>
                                            <Td fontWeight={'bold'}>Customer Name</Td>
                                            <Td>{a}</Td>




                                        </Tr>
                                        <Tr>
                                            <Td fontWeight={'bold'}>Mobile</Td>
                                            <Td>{b}</Td>
                                            <Td fontWeight={'bold'}>Email</Td>
                                            <Td>{c}</Td>




                                        </Tr>
                                        <Tr>
                                            <Td fontWeight={'bold'}>Company Name</Td>
                                            <Td>{d}</Td>
                                            <Td fontWeight={'bold'}>Landline</Td>
                                            <Td>{e}</Td>




                                        </Tr>
                                        
                                       
                                        <Tr>
                                            <Td fontWeight={'bold'}>Customer Group</Td>
                                            <Td>{j}</Td>
                                            <Td fontWeight={'bold'}>Customer Descriptions</Td>
                                            <Td>{k}</Td>




                                        </Tr>
                                        <Tr>
                                          
                                            <Td fontWeight={'bold'}>Address</Td>
                                            <Td>{m}</Td>
                                            <Td fontWeight={'bold'}>City</Td>
                                            <Td>{n}</Td>



                                        </Tr>
                                        <Tr>
                                          

                                            <Td fontWeight={'bold'}>Area</Td>
                                            <Td>{o}</Td>

                                            <Td fontWeight={'bold'}>Zip_code</Td>
                                            <Td>{p}</Td>


                                        </Tr>
                                       

                                    </Tbody>

                                </Table>
                            </TableContainer>


                            <TableContainer mt={5}>
                                <Table size='sm' variant='striped' colorScheme='gray' border='2px' borderColor='#9f1d21'>
                                   
                                    <Thead backgroundColor={'#9f1d21'}>
                                        <Tr>
                                            <Th color={'white'}>S No.</Th>
                                            <Th color={'white'}>Comments</Th>
                                            <Th color={'white'}>Comment By</Th>
                                        
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {

                                            userss.map((curElem, index) => {
                                                return (

                                         <Tr  key={index}>
                                            <Td>{index + 1}</Td>
                                            <Td>{curElem.Comment}</Td>
                                            <Td>{curElem.commentby}</Td>
                                          



                                        </Tr>
                                           )
                                        })
                                      }
                                        

                                    </Tbody>

                                </Table>
                            </TableContainer>



                        </Container>
                    

                </div>
            </div>

          </div>
        </div>
      </div>
        </>
    );
};




export default ViewCustomer;