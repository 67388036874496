import {
  Button,
  Container,
  Heading,
  Input,
  Text,
  VStack,
  Stack, Image, HStack, Center
} from '@chakra-ui/react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
const Login = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertt, setAlertt] = useState();

  const handelEmail = (e) => {
    setEmail(e.target.value)
  }

  const handelPassword = (e) => {
    setPassword(e.target.value)
  }



  const handleSubmit = (event) => {
    console.log({ email, password })
    //Prevent page reload
    event.preventDefault();
    let fData = new FormData();
    fData.append('email', email);
    fData.append('password', password);

    axios.post('https://radhamadhavseva.com/api/login.php', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'yes') {
          secureLocalStorage.setItem('token', '1');
          secureLocalStorage.setItem('tokenn', email);
          secureLocalStorage.setItem('type', 'ad');
          window.location.href = '/Home';
          // navigate("/Home");
          ///alert(result.data)
        } else if (cHeck === 'yess') {
          secureLocalStorage.setItem('token', '1');
          secureLocalStorage.setItem('tokenn', email);
          secureLocalStorage.setItem('type', 'em');
          window.location.href = '/Home';

        } else {
          //alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };




  return (
    <Container maxW={'container.xl'} h={'99vh'}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize={'95%'}
      backgroundImage="url('newbg.png')" p={'1'}>


      {/* 
<HStack>
<Image
              borderRadius='5px'
              marginLeft='5px'
              boxSize='30px'
              src='iskcon_logo.jpg'
              alt='ISKCON'
            />
           <Heading

             bgGradient='linear(to-l, #7928CA, #9f1d21)'
             bgClip='text'
             fontSize='35px'
             fontWeight='extrabold'

           >


             RADHAMADHAVSEVA


           </Heading>
         </HStack> */}

      <form onSubmit={handleSubmit}>

        <VStack
          alignItems={'stretch'}
          spacing={'5'}
          w={['full', '96']}
          top={'38%'}
          left={'62%'}
          position='absolute'
          bg={'white'}
          p={1}
          float={'right'}
        >



          <Input
            placeholder={'Email'}
            type={'text'}
            onChange={handelEmail}
            required
            focusBorderColor={'#9f1d21'}
          />

          <Input
            placeholder={'Password'}
            type={'password'}
            onChange={handelPassword}
            required
            focusBorderColor={'#9f1d21'}
          />

          {/* <Button variant={'link'} alignSelf={'flex-end'}>
            <Link to={'/forgetpassword'}>Forget Password?</Link>
          </Button> */}

          <Button bgGradient='linear(to-r, teal.500, green.500)' colorScheme={'purple'} type={'submit'}>
            Log In
          </Button>

          {alertt === 'yes' &&
            <Stack spacing={3} mt={'40px'} >
              <Alert status='success'>
                <AlertIcon />
                Successfully Logged in
              </Alert>
            </Stack>
          }

          {alertt === 'no' &&
            <Stack spacing={3} mt={'40px'} >
              <Alert status='error'>
                <AlertIcon />
                Enter Valid login credentials !
              </Alert>
            </Stack>
          }


        </VStack>
      </form>


    </Container>
  );


};

export default Login;
