import { Box, Container, Heading, Image, Stack, Text, Collapse, Button,SimpleGrid, Flex, HStack, collapse, IconButton, VStack, InputGroup, InputLeftAddon, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, useDisclosure, Textarea } from '@chakra-ui/react'
import { Link, useNavigate,   useParams  } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import axios from 'axios';
import Navbar from './Navbar';

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Center
} from '@chakra-ui/react'


const headingOptions = {
    pos: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    textTransform: 'uppercase',
    p: '4',
    size: '4xl',
};

const EditMsgCenter = () => {

    const [users, setUsers] = useState([]);
    const [collapse, setCollapse] = React.useState(true);

    const [format, setFormat] = useState([]);
    const { id } = useParams();
    const [fid, setFid] = useState([]);

    const [alertt, setAlertt] = useState();
    const navigate = useNavigate();
   
    
    const [a, setData_A] = useState([]);
    const [idd, setData_ID] = useState([]);
  
    const [userss, setPosts] = useState([]);
    const apiEndPoint = 'https://radhamadhavseva.com/api/msgcenter.php?edit='+id;
  
    useEffect(() =>{
      const getPosts = async () =>{
          const {data: res} =  await axios.get(apiEndPoint);
          setPosts(res);
          setData_ID(res[0].id);
          setData_A(res[0].phone);
      };
      getPosts();
      
    }, []);
   

    const handelFormat = (e) => {
        setFormat(e.target.value)
        setData_A(e.target.value)
    }



    const handleSubmit = (event) => {

        //Prevent page reload
        event.preventDefault();
        let fData = new FormData();
        fData.append('for', a);
        fData.append('id', id);

        axios.post('https://radhamadhavseva.com/api/msgcenter.php?updt=updt', fData)

            .then(result => {
                console.log(result.data)
                const cHeck = result.data;
                setAlertt(result.data);
                if (cHeck === 'yes') {

                    setTimeout(() => {

                       navigate('/MsgCenter');

                    }, 1000);

                    ///alert(result.data)
                } else {
                    // alert(result.data)
                }


            })

            .catch(error => {
                alert('not success')
                console.log(error)
            })

    };


    return (
        <>

<Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
           
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

                      Edit Message Center</Heading>
            <div class="container-fluid  border">
              <div class="row justify-content-center">
                    
              <form class="col-lg-8 mb-3 mt-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4}>

                   
                               
                  <div class="row">
  <div class="col-lg-8">                            

<InputGroup mt={4}>
    <InputLeftAddon children='+91' />
    <Input
     required
     focusBorderColor={'#9f1d21'}
    onChange={handelFormat}
    value={a}
    color={'black'}
      size='md'
    type='tel' placeholder='phone number' />
  </InputGroup>

  </div>

<div class="col-lg-4">

                                    <Button bg={'#9f1d21'} color={'white'} type={'submit'}  mt={4}>
                                        Submit
                                    </Button>
                                    </div>
                       </div>
                                    </SimpleGrid>
                                </form>

                                {alertt === 'yes' &&
                                    <Stack spacing={3} mt={'40px'} >
                                        <Alert status='success'>
                                            <AlertIcon />
                                            Updated Successfully
                                        </Alert>
                                    </Stack>
                                }

                                {alertt === 'no' &&
                                    <Stack spacing={3} mt={'40px'} >
                                        <Alert status='error'>
                                            <AlertIcon />
                                            Format Not Updated !
                                        </Alert>
                                    </Stack>
                                }

                                  

</div>
</div>

</div>
</div>
</div>

        </>
    );
};


export default EditMsgCenter;

