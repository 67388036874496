import { Box, Container, Heading, Image, Stack, Text, Collapse, Button, Flex, HStack, collapse, IconButton, VStack, InputGroup, InputLeftAddon, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, useDisclosure, Textarea } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import axios from 'axios';
import Navbar from './Navbar';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';

import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Center
} from '@chakra-ui/react'


const headingOptions = {
    pos: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    textTransform: 'uppercase',
    p: '4',
    size: '4xl',
};

const ChangePaswd = () => {

    const [cnf, setCnf] = useState([]);
    const [collapse, setCollapse] = React.useState(true);

    const [old, setOld] = useState([]);

    const [neww, setNeww] = useState([]);

    const [alertt, setAlertt] = useState();
    const navigate = useNavigate();


    const [a, setData_A] = useState([]);
    const [idd, setData_ID] = useState([]);

    const [userss, setPosts] = useState([]);
    const apiEndPoint = 'https://radhamadhavseva.com/api/whatsapp.php?edit=1';

    useEffect(() => {
        const getPosts = async () => {
            const { data: res } = await axios.get(apiEndPoint);
            setPosts(res);
            setData_ID(res[0].id);
            setData_A(res[0].phone);
        };
        getPosts();

    }, []);


    const handelOld = (e) => {
        setOld(e.target.value)

    }


    const handelNew = (e) => {
        setNeww(e.target.value)

    }

    const handelCnf = (e) => {
        setCnf(e.target.value)

    }



    const handleSubmit = (event) => {

        //Prevent page reload
        event.preventDefault();
        let fData = new FormData();

        fData.append('old', old);
        fData.append('new', neww);

        if (neww === cnf) {

            axios.post('https://radhamadhavseva.com/api/change.php', fData)

                .then(result => {
                    console.log(result.data)
                    const cHeck = result.data;
                    setAlertt(result.data);
                    if (cHeck === 'yes') {

                        setTimeout(() => {

                            navigate('/login');
                            window.location.reload(true);

                        }, 1000);

                        ///alert(result.data)
                    } else {
                        // alert(result.data)
                    }


                })

                .catch(error => {
                    alert('not success')
                    console.log(error)
                })

        } else {
            setAlertt('Confirm Password not match !');
        }

    };


    return (
        <>
            <Navbar />
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


                        <div class='sticky-left position-fixed  sidebar'>

                            <Header />


                        </div>

                    </div>
                    <div class="col-lg-10 top-0  end-0">
                        <div class="container-fluid mt-4">
                            <div class="row">
                                <Heading
                                    fontSize={'17px'}
                                    backgroundColor='#9f1d21'
                                    color={'white'}
                                    p={2}

                                >

                                    Change Password </Heading>


                                <Box
                                    p='20px'
                                    mt='1'
                                    rounded='md'
                                    shadow='md'
                                >
                                    <form onSubmit={handleSubmit} mt={'10px'}>


                                        <Input
                                            h={9}
                                            required
                                            focusBorderColor={'#9f1d21'}
                                            onChange={handelNew}
                                            color={'black'}
                                            size='md'
                                            type='password' placeholder='enter new password' />


                                        <Input
                                            h={9}
                                            required
                                            focusBorderColor={'#9f1d21'}
                                            onChange={handelCnf}
                                            color={'black'}
                                            size='md'
                                            mt={6}
                                            type='password' placeholder='enter Confirm password' />



                                        <Button bg={'#9f1d21'} float={'right'} color={'white'} type={'submit'} mt={4} size='sm'>
                                            Update
                                        </Button>
                                    </form>

                                    {alertt === 'yes' &&
                                        <Stack spacing={3} mt={'40px'} >
                                            <Alert status='success'>
                                                <AlertIcon />
                                                Updated Successfully
                                            </Alert>
                                        </Stack>
                                    }



                                    {alertt === 'Confirm Password not match !' &&
                                        <Stack spacing={3} mt={'40px'} >
                                            <Alert status='error'>
                                                <AlertIcon />
                                                Confirm Password not match !
                                            </Alert>
                                        </Stack>
                                    }



                                    {alertt === 'no' &&
                                        <Stack spacing={3} mt={'40px'} >
                                            <Alert status='error'>
                                                <AlertIcon />
                                                Not updated !
                                            </Alert>
                                        </Stack>
                                    }



                                </Box>









                        </div>
                    </div>

                </div>

            </div>
        </div>

        </>
    );
};




export default ChangePaswd