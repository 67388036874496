import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Login from './components/Login';
import Upload from './components/Upload';
import View from './components/View';
import Logout from './components/Logout';
import AddCustomer from './components/AddCustomer';
import AddCampaign from './components/AddCampaign';
import CampaignList from './components/CampaignList';
import EmployeList from './components/EmployeList';
import EmployeReg from './components/EmployeReg';
import Contact from './components/Contact';
import { Navigate } from "react-router-dom";
import  {useEffect} from 'react';
import EditCampaign from './components/EditCampaign';
import EditCustomer from './components/EditCustomer';
import EditEmploye from './components/EditEmploye';
import AddSmsFormat from './components/AddSmsFormat';
import EditSmsFormat from './components/EditSmsFormat';
import MsgCenter from './components/MsgCenter';
import EditMsgCenter from './components/EditMsgCenter';
import WhatsAppSet  from './components/WhatsAppSet';
import EmailSet from './components/EmailSet';
import ModeSet from './components/ModeSet';
import ViewCustomer from './components/ViewCustomer';
import ChangePaswd from './components/ChangePaswd';
import  secureLocalStorage  from  "react-secure-storage";
import Navbar from './components/Navbar';
import CampaignLogs from './components/CampaignLogs';
import BulkLogs from './components/BulkLogs';


function App() {

  const isAuthenticated = secureLocalStorage.getItem("token");

  // if (!secureLocalStorage.getItem("token")) {
  //   <Login />
  // }

  
  //  const loggedInUser =  secureLocalStorage.getItem("token");
    
  

  
  return (
    <Router>
    
      <Routes>
      <Route path="/*" element={isAuthenticated==='1' ?   <Home /> : <Login /> } />
        <Route path="/" element={isAuthenticated==='1' ?   <Home /> : <Login /> } />
        <Route path="/Home" element={isAuthenticated==='1' ?  <Home /> : <Login />  } />
        <Route path="/AddCustomer" element={isAuthenticated==='1' ?  <AddCustomer /> : <Login />  } />
        <Route path="/AddCampaign" element={isAuthenticated==='1' ?  <AddCampaign /> : <Login />  } />
        <Route path="/CampaignList" element={isAuthenticated==='1' ?  <CampaignList /> : <Login />  } />
        <Route path="/EmployeList" element={isAuthenticated==='1' ?  <EmployeList /> : <Login />  } />
        <Route path="/EmployeReg" element={isAuthenticated==='1' ?  <EmployeReg /> : <Login />  } />
        <Route path="/Contact" element={isAuthenticated==='1' ?  <Contact /> : <Login />  } />
        <Route path="/View" element={isAuthenticated==='1' ? <View /> : <Login /> } />
        <Route path="/upload" element={isAuthenticated==='1' ? <Upload /> : <Login />} />
        <Route path="/AddCampaign/:id" element={isAuthenticated==='1' ?  <AddCampaign /> : <Login />  } />
        <Route path="/CampaignList/:id" element={isAuthenticated==='1' ?  <CampaignList /> : <Login />  } />
        <Route path="/CampaignList/:idd" element={isAuthenticated==='1' ?  <CampaignList /> : <Login />  } />
        <Route path="/EditCampaign/:id" element={isAuthenticated==='1' ?  <EditCampaign /> : <Login />  } />
        <Route path="/EditCustomer/:id" element={isAuthenticated==='1' ?  <EditCustomer /> : <Login />  } />
        <Route path="/EditEmploye/:id" element={isAuthenticated==='1' ?  <EditEmploye /> : <Login />  } />
        <Route path="/AddSmsFormat" element={isAuthenticated==='1' ?  <AddSmsFormat /> : <Login />  } />
        <Route path="/EditSmsFormat/:id" element={isAuthenticated==='1' ?  <EditSmsFormat /> : <Login />  } />
        <Route path="/MsgCenter" element={isAuthenticated==='1' ?  <MsgCenter /> : <Login />  } />
        <Route path="/EditMsgCenter/:id" element={isAuthenticated==='1' ?  <EditMsgCenter /> : <Login />  } />
        <Route path="/WhatsAppSet" element={isAuthenticated==='1' ?  <WhatsAppSet /> : <Login />  } />
        <Route path="/EmailSet" element={isAuthenticated==='1' ?  <EmailSet /> : <Login />  } />
        <Route path="/ModeSet" element={isAuthenticated==='1' ?  <ModeSet /> : <Login />  } />
        <Route path="/ViewCustomer/:id" element={isAuthenticated==='1' ?  <ViewCustomer /> : <Login />  } />
        <Route path="/ChangePaswd" element={isAuthenticated==='1' ?  <ChangePaswd /> : <Login />  } />
        <Route path="/Navbar" element={isAuthenticated==='1' ?  <Navbar /> : <Login />  } />
        <Route path="/CampaignLogs" element={isAuthenticated==='1' ?  <CampaignLogs /> : <Login />  } />
        <Route path="/BulkLogs" element={isAuthenticated==='1' ?  <BulkLogs /> : <Login />  } />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
