import { Box, Container, Heading, Image, Stack, Text, Collapse, Button, Flex, HStack, collapse, IconButton, VStack, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, SimpleGrid,useDisclosure, Textarea } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom';
import { BiSlider, BiEdit, BiTrash, BiBlock, BiCheckSquare } from 'react-icons/bi';
import axios from 'axios';
import Navbar from './Navbar';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Center
} from '@chakra-ui/react'


const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const AddSmsFormat = () => {

  const [users, setUsers] = useState([]);
  const [collapse, setCollapse] = React.useState(true);

  const [format, setFormat] = useState([]);
  const [dlt, setDlt] = useState([]);

  const [fid, setFid] = useState([]);

  const [alertt, setAlertt] = useState();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/smsformat_view.php');
    setUsers(await response.json());

  }


  const handelFormat = (e) => {
    setFormat(e.target.value)
  }

  
  const handelDlt = (e) => {
    setDlt(e.target.value)
  }



  const handleSubmit = (event) => {

    //Prevent page reload
    event.preventDefault();
    let fData = new FormData();
    fData.append('for', format);
    fData.append('dlt', dlt);

    axios.post('https://radhamadhavseva.com/api/smsformat.php?sav=sav', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'yes') {

          setTimeout(() => {

            window.location.reload(true);

          }, 1000);

          ///alert(result.data)
        } else {
          // alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };



  useEffect(() => {
    getUsers();
  }, []);

  const { isOpen, onToggle } = useDisclosure()

  function Delete(id) {
    axios.post('https://radhamadhavseva.com/api/smsformat.php?del=' + id).then(result => {
      if (result.data === 'yes') {
        navigate('/AddSmsFormat');
        window.location.reload(true);
      } else {
        alert(result.data)
        navigate('/AddSmsFormat');
      }
    })
  }


  return (
    <>
   <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          
                
    
<div class="col-lg-10 top-0  end-0">
<TableContainer w={'full'} >
<Table marginBottom={'12px'} w={'full'} border="2px" borderColor={"#9f1d21"} backgroundColor={'#9f1d21'}>

<Thead>



  <Tr marginRight={5}>
    
    <Td>
      <Heading fontSize={'18px'} color={'white'}>SMS Templates</Heading>
    </Td>
    <Td>

      <Input placeholder='Search' w={'500px'} onChange={(e) => setQuery(e.target.value)} size='md' border="2px" borderColor={"#9f1d21"} borderRadius={'105px'} backgroundColor={'white'} />

    </Td>
    <Td>
      <Button bg={'white'} color={'black'} onClick={onToggle}>+</Button>
    </Td>
  </Tr>

</Thead>
</Table>
</TableContainer>
            <div class="container-fluid border">
              <div class="row">
       
      


              <Collapse in={isOpen} animateOpacity>
              <form class="col-lg-12 mb-3 mt-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4}>
                  
                  <Input
                      placeholder={'Enter DLT Template Id'}
                      type={'text'}
                      h={9}
                      required
                      borderColor={"#9f1d21"}
                      onChange={handelDlt}
                      color={'black'}
                      size='md'
                    />

                    <Textarea
                      placeholder={'Paste Here SMS Template'}
                      type={'text'}
                      h={9}
                      mt={3}
                      required
                      borderColor={"#9f1d21"}
                      onChange={handelFormat}
                      color={'black'}
                      size='md'
                    />

<Center>
                    <Button backgroundColor={'#9f1d21'} color={'white'} mt={4} type={'submit'}>
                      Submit
                    </Button>
                    </Center>
                    </SimpleGrid>
                  </form>

                  {alertt === 'yes' &&
                    <Stack spacing={3} mt={'40px'} >
                      <Alert status='success'>
                        <AlertIcon />
                        Format Successfully Registered
                      </Alert>
                    </Stack>
                  }

                  {alertt === 'no' &&
                    <Stack spacing={3} mt={'40px'} >
                      <Alert status='error'>
                        <AlertIcon />
                        Format Not saved !
                      </Alert>
                    </Stack>
                  }

                  {alertt === 'Format Already saved' &&
                    <Stack spacing={3} mt={'40px'} >
                      <Alert status='error'>
                        <AlertIcon />
                        Format Already saved !
                      </Alert>
                    </Stack>
                  }


              </Collapse>

              

               
               

                    <TableContainer w={'full'} marginBottom={9}>
                      <Table size='sm' mt={3} variant='striped' colorScheme='gray' border='2px' borderColor='#9f1d21'>

                        <Thead backgroundColor={'#9f1d21'}>
                          <Tr>
                            <Th color={'white'}>DLT Template Id</Th>
                            <Th color={'white'}>Templates</Th>


                            <Th color={'white'}>Action</Th>
                           

                          </Tr>
                        </Thead>
                        <Tbody>
                          {

                            users.filter(user => user.format.toLowerCase().includes(query)).map((curElem, index) => {
                              return (

                                <Tr key={index}>
                                  <Td>{curElem.dlt_template_id}</Td>
                                  <Td>{curElem.format.slice(0, 50)}<br/>
                                  {curElem.format.slice(50, 100)}<br/>
                                  {curElem.format.slice(100, 150)}<br/>
                                  {curElem.format.slice(150, 200)}<br/>
                                  {curElem.format.slice(200, 250)}
                                 
                                  </Td>
                                  <Td>
                                    <HStack>
                                    <Button variant='solid' size="sm" bg='white' onClick={() => navigate(`/EditSmsFormat/${curElem.id}`)}>
                                      <BiEdit fontSize={20} />
                                    </Button>
                                
                                    <Link variant='solid' colorScheme='green' onClick={() => Delete(curElem.id)}>
                                      <BiTrash fontSize={20} />
                                    </Link>
                                    </HStack>
                                  </Td>

                                </Tr>

                              )
                            })
                          }
                        </Tbody>

                      </Table>
                    </TableContainer>
                 




               
        </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};


export default AddSmsFormat;
