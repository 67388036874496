import { Box, Container, Heading, Image, Stack, Text, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack, Input } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { InputLeftAddon, InputGroup,Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import Navbar from './Navbar';
import axios from 'axios';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const AddCustomer = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const [collapse, setCollapse] = React.useState(true);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');

  const [group, setGroup] = useState('');
  const [location, setLocation] = useState('');

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [area, setArea] = useState('');
  const [zipcode, setZipcode] = useState('');


  const [alertt, setAlertt] = useState();

  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/view.php');
    setUsers(await response.json());

  }



  const handelName = (e) => {
    setName(e.target.value)
  }

  const handelMobile = (e) => {
    setMobile(e.target.value)
  }

  const handelEmail = (e) => {
    setEmail(e.target.value)
  }

  const handelCompany = (e) => {
    setCompany(e.target.value)
  }

  const handelDesignation = (e) => {
    setDesignation(e.target.value)
  }



  const handelGroup = (e) => {
    setGroup(e.target.value)
  }

  const handelLocation = (e) => {
    setLocation(e.target.value)
  }


  const handelAddress = (e) => {
    setAddress(e.target.value)
  }

  const handelCity = (e) => {
    setCity(e.target.value)
  }


  const handelArea = (e) => {
    setArea(e.target.value)
  }

  const handelZipcode = (e) => {
    setZipcode(e.target.value)
  }




  const handleSubmit = (event) => {

    //Prevent page reload
    event.preventDefault();
    let fData = new FormData();
    fData.append('name', name);
    fData.append('mobile', mobile);
    fData.append('email', email);
    fData.append('company', company);
    fData.append('designation', designation);

    fData.append('group', group);
    fData.append('location', location);

    fData.append('address', address);
    fData.append('city', city);
    fData.append('area', area);
    fData.append('zipcode', zipcode);



    axios.post('https://radhamadhavseva.com/api/insert.php', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'yes') {
          // secureLocalStorage.setItem('token', '1');
          // window.location.href = '/Home';
          navigate("/");
          // alert(result.data)
        } else {
          // alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };



  useEffect(() => {
    getUsers();
  }, []);



  return (
    <>
      <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

                      Add New Customer</Heading>
            <div class="container-fluid  border">
          
              <div class="row justify-content-center mt-3 ">


                {alertt === 'yes' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='success'>
                      <AlertIcon />
                      Customer Successfully Saved
                    </Alert>
                  </Stack>
                }

                {alertt === 'no' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='error'>
                      <AlertIcon />
                      Customer Not saved !
                    </Alert>
                  </Stack>
                }

                {alertt === 'Customer Already saved' &&
                  <Stack spacing={3} mt={'40px'} >
                    <Alert status='error'>
                      <AlertIcon />
                      Customer Already saved !
                    </Alert>
                  </Stack>
                }




                <form class="col-lg-8 mb-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4}>

                   

                    <Input
                      placeholder={'Customer Name'}
                      type={'text'}

                      onChange={handelName}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <InputGroup>
                      <InputLeftAddon children='+91' />
                      <Input type='tel'
                        onChange={handelMobile}
                        required
                        focusBorderColor={'#9f1d21'}
                        placeholder='phone number' />
                    </InputGroup>


                    <Input
                      placeholder={'Email'}
                      type={'email'}

                      onChange={handelEmail}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <Input
                      placeholder={'Company Name'}
                      type={'text'}

                      onChange={handelCompany}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <Input
                      placeholder={'Landline No.'}
                      type={'text'}

                      onChange={handelDesignation}
                      required
                      focusBorderColor={'#9f1d21'}
                    />




                    <Input
                      placeholder={'Customer Group'}
                      type={'text'}

                      onChange={handelGroup}
                      required
                      focusBorderColor={'#9f1d21'}
                    />





                    <Input
                      placeholder={'Customer Description'}
                      type={'text'}

                      onChange={handelLocation}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <Input
                      placeholder={'Address'}
                      type={'text'}

                      onChange={handelAddress}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <Input
                      placeholder={'City'}
                      type={'text'}

                      onChange={handelCity}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <Input
                      placeholder={'Area'}
                      type={'text'}

                      onChange={handelArea}
                      required
                      focusBorderColor={'#9f1d21'}
                    />

                    <Input
                      placeholder={'Zip Code'}
                      type={'text'}

                      onChange={handelZipcode}
                      required
                      focusBorderColor={'#9f1d21'}
                    />



<Center>
                    <Button bg={'#9f1d21'} w={40} color={'white'} type={'submit'}>
                      Submit
                    </Button>
                    </Center>
                  </SimpleGrid>



                </form>

              </div>
            </div>

          </div>
        </div>
      </div>



    </>
  );
};


export default AddCustomer;
