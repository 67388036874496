import { Box, Container, Heading, Image, Stack, Text, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack, Input, Hide } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {InputLeftAddon,InputGroup } from '@chakra-ui/react'
import { Link,   useParams,  useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { BiSlider } from 'react-icons/bi';
import axios from 'axios';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const EditCustomer = () => {
  
    const [users, setUsers] = useState([]);
    const [collapse, setCollapse] = React.useState(true);
    const { id } = useParams();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [company, setCompany] = useState('');
    const [designation, setDesignation] = useState('');
    const [salary, setSalary] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDob] = useState('');
    const [group, setGroup] = useState('');
    const [location, setLocation] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [leadtype, setLeadtype] = useState('');
    const navigate = useNavigate();
    const [alertt,  setAlertt] = useState();

    const [a, setData_A] = useState([]);
    const [b, setData_B] = useState([]);
    const [c, setData_C] = useState([]);
    const [d, setData_D] = useState([]);
    const [e, setData_E] = useState([]);
    const [f, setData_F] = useState([]);

    const [g, setData_G] = useState([]);
    const [h, setData_H] = useState([]);
    const [i, setData_I] = useState([]);
    const [j, setData_J] = useState([]);
    const [k, setData_K] = useState([]);
    const [l, setData_L] = useState([]);
    const [m, setData_M] = useState([]);
    const [n, setData_N] = useState([]);
    const [o, setData_O] = useState([]);
    const [p, setData_P] = useState([]);
    const [q, setData_Q] = useState([]);

    const [idd, setData_ID] = useState([]);
  
    const [userss, setPosts] = useState([]);
    const apiEndPoint = 'https://radhamadhavseva.com/api/edit_view.php?id='+id;
  
    useEffect(() =>{
      const getPosts = async () =>{
          const {data: res} =  await axios.get(apiEndPoint);
          setPosts(res);
          setData_ID(res[0].id);
          setData_A(res[0].Customer_Name);
          setData_B(res[0].Mobile);
          setData_C(res[0].Email);
          setData_D(res[0].Company_Name);
          setData_E(res[0].Landline);
          
          setData_J(res[0].Customer_Group);
          setData_K(res[0].Customer_Description);
         
          setData_M(res[0].Address);
          setData_N(res[0].City);
          setData_O(res[0].Area);
          setData_P(res[0].Zip_code);
         
      };
      getPosts();
      
    }, []);
  
    const handelID = (e) => {
        setData_ID(e.target.value)
      }
  
    const handelName = (e) => {
      setName(e.target.value)
      setData_A(e.target.value)
    }
  
    const handelMobile = (e) => {
      setMobile(e.target.value)
      setData_B(e.target.value)
    }
  
    const handelEmail = (e) => {
      setEmail(e.target.value)
      setData_C(e.target.value)
    }
  
    const handelCompany = (e) => {
      setCompany(e.target.value)
      setData_D(e.target.value)
    }
  
    const handelDesignation = (e) => {
      setDesignation(e.target.value)
      setData_E(e.target.value)
    }
  
  
  
  
    const handelGroup = (e) => {
      setGroup(e.target.value)
      setData_J(e.target.value)
    }
  
    const handelLocation = (e) => {
      setLocation(e.target.value)
      setData_K(e.target.value)
    }
  
  
  
  
    const handelAddress = (e) => {
      setAddress(e.target.value)
      setData_M(e.target.value)
    }
  
    const handelCity = (e) => {
      setCity(e.target.value)
      setData_N(e.target.value)
    }
  
  
    const handelArea = (e) => {
      setArea(e.target.value)
      setData_O(e.target.value)
    }
  
    const handelZipcode = (e) => {
      setZipcode(e.target.value)
      setData_P(e.target.value)
    }
  
   
  
  
    const handleSubmit = (event) => {
  
      //Prevent page reload
      event.preventDefault();
      let fData = new FormData();
      fData.append('cid', idd);
      fData.append('name', a);
      fData.append('mobile', b);
      fData.append('email', c);
      fData.append('company', d);
      fData.append('designation', e);
      
      fData.append('group', j);
      fData.append('location', k);
     
      fData.append('address', m);
      fData.append('city', n);
      fData.append('area', o);
      fData.append('zipcode', p);
    
  
  
      axios.post('https://radhamadhavseva.com/api/update.php', fData)
  
        .then(result => {
          console.log(result.data)
          const cHeck = result.data;
          setAlertt(result.data);
          if (cHeck === 'yes') {
            // sessionStorage.setItem('token', '1');
            // window.location.href = '/Home';
            // navigate("/Home");
           // alert(result.data)
           setTimeout(() => {

            navigate("/View");

          }, 1000);
          } else {
            alert(result.data)
          }
  
  
        })
  
        .catch(error => {
          alert('not success')
          console.log(error)
        })
  
    };
  
  
  
  
    return (
      <>

   <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
            <div class="container-fluid  mt-4">
              <div class="row justify-content-center">
        
  
              {alertt==='yes' &&
          <Stack spacing={3} mt={'40px'} >
          <Alert status='success'>
            <AlertIcon />
            Customer Successfully Updated
          </Alert>
        </Stack>
        }
  
  {alertt==='no' &&
          <Stack spacing={3} mt={'40px'} >
          <Alert status='error'>
            <AlertIcon />
            Customer Not Update !
          </Alert>
        </Stack>
        }
  
 
  
  
  
  
  <form class="col-lg-8 mb-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4}>

                    <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

Edit Customer</Heading>
                  
                   
                        <Hide>
                        <Input
                          placeholder={'Customer Name'}
                          type={'text'}
                         
                          onChange={handelID}
                          value=  {idd}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                        </Hide>
                  
                     
                        <Input
                          placeholder={'Customer Name'}
                          type={'text'}
                         
                          onChange={handelName}
                          value=  {a}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                      
                      <InputGroup>
      <InputLeftAddon children='+91' />
      <Input type='tel' 
             onChange={handelMobile}
             value=  {b}
             required
             focusBorderColor={'#9f1d21'}
             placeholder='phone number' />
    </InputGroup>
                        
                     
                        <Input
                          placeholder={'Email'}
                          type={'email'}
                         
                          onChange={handelEmail}
                          value=  {c}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                      
                        <Input
                          placeholder={'Company Name'}
                          type={'text'}
                         
                          onChange={handelCompany}
                          value=  {d}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                     
                        <Input
                          placeholder={'Designation'}
                          type={'text'}
                         
                          onChange={handelDesignation}
                          value=  {e}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                     
                        <Input
                          placeholder={'Customer Group'}
                          type={'text'}
                         
                          onChange={handelGroup}
                          value=  {j}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                     
  
                
  



                        <Input
                          placeholder={'Data Location'}
                          type={'text'}
                         
                          onChange={handelLocation}
                          value=  {k}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                      
                        <Input
                          placeholder={'Address'}
                          type={'text'}
                         
                          onChange={handelAddress}
                          value=  {m}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                    
                        <Input
                          placeholder={'City'}
                          type={'text'}
                         
                          onChange={handelCity}
                          value=  {n}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                    
  
                    
                        <Input
                          placeholder={'Area'}
                          type={'text'}
                         
                          onChange={handelArea}
                          value=  {o}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                     
                        <Input
                          placeholder={'Zip Code'}
                          type={'text'}
                         
                          onChange={handelZipcode}
                          value=  {p}
                          required
                          focusBorderColor={'#9f1d21'}
                        />
                    
  
                    
                        <Button bg={'#9f1d21'} color={'white'} type={'submit'}>
                         Update
                        </Button>
                     
  
                
</SimpleGrid>
                  </form>

</div>
</div>

</div>
</div>
</div>

  
      </>
    );
  };
  
  
export default EditCustomer