import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Collapse,
  Box,
  Hide

} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { BiMenuAltLeft, BiHomeAlt, BiUser, BiChalkboard, BiBrightness, BiHelpCircle, BiLogOut, BiLogIn } from 'react-icons/bi';
import { Fade, ScaleFade, Slide, SlideFade } from '@chakra-ui/react'
import secureLocalStorage from "react-secure-storage";

const Header = () => {

  const c = secureLocalStorage.getItem("token");
  console.log(c);


  const Buttonshow = () => {

    if (c === '1') {
      return <Link to={'/login'}><Button
        onClick={Clear}
        variant={'ghost'}
        color={'#9f1d21'}
        top={4}
      >
        <BiLogOut fontSize={20} />
        <Text marginLeft={6}>Logout</Text>
      </Button></Link>;

    } else {

      return <Link to={'/login'}><Button
        onClick={onClose}
        variant={'ghost'}
        color={'#9f1d21'}
        top={4}
      >
        <BiLogIn fontSize={20} />
        <Text marginLeft={6}>Login</Text>
      </Button></Link>;
    }

  }

  const Clear = () => {

    secureLocalStorage.clear("token");
    secureLocalStorage.clear("tokenn");
    secureLocalStorage.clear("type");
    window.location.href = '/login';



  }



  const { isOpen, onOpen, onClose } = useDisclosure();





  ///CUSTOMER MENU

  function Customer() {
    const { isOpen, onToggle } = useDisclosure()

    return (
      <>


        <Button top={4} onClick={onToggle} variant={'ghost'} color={'#9f1d21'}>
          <BiUser fontSize={20} />
          <Text marginLeft={6}>Customer</Text>

        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p='1px'
            w={'200px'}
            color='white'
            mt='3'
            bg='#9f1d21'
            rounded='md'
            shadow='md'
          >
            <Link to={'/AddCustomer'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Add New Customer
              </Button>
            </Link>
            <Link to={'/View'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                View Customer
              </Button>
            </Link>

            <Link to={'/Upload'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Bulk Upload
              </Button>
            </Link>

            <Link to={'/BulkLogs'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                View Logs
              </Button>
            </Link>

          </Box>
        </Collapse>
      </>
    )
  }


  ////Campaign MENU

  function Campaign() {
    const { isOpen, onToggle } = useDisclosure()

    return (
      <>
        <Button top={4} onClick={onToggle} variant={'ghost'} color={'#9f1d21'}>

          <BiChalkboard fontSize={20} />
          <Text marginLeft={6}>Campaign</Text>

        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p='5px'
            color='white'
            w={'200px'}
            mt='3'
            bg='#9f1d21'
            rounded='md'
            shadow='md'
          >
            <Link to={'/AddCampaign'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Add New Campaign
              </Button>
            </Link>
            <Link to={'/CampaignList'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Campaign List
              </Button>
            </Link>
            <Link to={'/CampaignLogs'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                View Logs
              </Button>
            </Link>


          </Box>
        </Collapse>
      </>
    )
  }



  ////Help

  function Help() {
    const { isOpen, onToggle } = useDisclosure()

    return (
      <>
        <Button top={4} onClick={onToggle} variant={'ghost'} color={'#9f1d21'}>

          <BiHelpCircle fontSize={20} />
          <Text marginLeft={6}>Help</Text>
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p='5px'
            color='white'
            mt='3'
            w={'200px'}
            bg='#9f1d21'
            rounded='md'
            shadow='md'
          >
            <Link to={'/Contact'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Contact us
              </Button>
            </Link>


          </Box>
        </Collapse>
      </>
    )
  }


  ///Employe

  function Employe() {
    const { isOpen, onToggle } = useDisclosure()

    return (
      <>
        <Button top={4} onClick={onToggle} variant={'ghost'} color={'#9f1d21'}>

          <BiBrightness fontSize={20} />
          <Text marginLeft={6}>Employee</Text>
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p='5px'
            color='white'
            mt='3'
            w={'200px'}
            bg='#9f1d21'
            rounded='md'
            shadow='md'
          >
            <Link to={'/EmployeReg'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Employee Registration
              </Button>
            </Link>

            <Link to={'/EmployeList'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Employee List
              </Button>
            </Link>




          </Box>
        </Collapse>
      </>
    )
  }



  const type = secureLocalStorage.getItem("type");

  ////Setting

  function Setting() {
    const { isOpen, onToggle } = useDisclosure()

    return (
      <>
        <Button top={4} onClick={onToggle} variant={'ghost'} color={'#9f1d21'}>

          <BiBrightness fontSize={20} />
          <Text marginLeft={6}>Setting</Text>
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p='5px'
            color='white'
            w={'200px'}
            mt='3'
            bg='#9f1d21'
            rounded='md'
            shadow='md'

          >
            <Link to={'/AddSmsFormat'}>
              <Button
                onClick={onClose}
                colorScheme={'white'}
                fontSize={13}
              >
                SMS Templates
              </Button>
            </Link>

            <Link to={'/MsgCenter'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}

              >
                Message Center
              </Button>
            </Link>

            <Link to={'/ModeSet'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Modes setting
              </Button>
            </Link>
            <Hide>
              <Link to={'/WhatsAppSet'}>
                <Button
                  onClick={onClose}

                  colorScheme={'white'}
                  fontSize={13}
                >
                  What's app setting
                </Button>
              </Link>
            </Hide>
            <Link to={'/EmailSet'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Email setting
              </Button>
            </Link>

            <Link to={'/ChangePaswd'}>
              <Button
                onClick={onClose}

                colorScheme={'white'}
                fontSize={13}
              >
                Change Password
              </Button>
            </Link>







          </Box>
        </Collapse>
      </>
    )
  }




  return (
    <>

      {/* 
      <Button
        zIndex={'overlay'}
        pos={'fixed'}
        float={'right'}
        top={'4'}
        right={'4'}
        p={'3'}
        w={'200'}
        h={'10'}
      backgroundColor={'white'}
       
      > <Buttonshow />  
      </Button> */}
      {/* <Box 
         overflowY="auto"
       
         height={800}
         css={{
          '&::-webkit-scrollbar':{
            width:'4px',
          
          },
          '&::-webkit-scrollbar-track':{
            width:'6px',
           
          },
          '&::-webkit-scrollbar-thumb':{
            borderRadius:'24px',
            
          }

         }}
         
         >
     
            <VStack alignItems={'flex-start'}>
         
   <Link to={'/Home'}>
                <Button
                  onClick={onClose}
                  variant={'ghost'}
                  color={'#9f1d21'}
                   top={4} 
                >
                  <BiHomeAlt  color={'#9f1d21'} fontSize={20}   />
                 <Text marginLeft={6}> Dashboard</Text>
                </Button>
              </Link>

              <Customer />
              <Campaign />
              {type==='em' ?  ''  :  <Employe /> } 

              {type==='em' ?  ''  : <Setting /> } 

              
              
              <Help />
              <Buttonshow /> 
         
       
                 
          
            </VStack>
            </Box> */}


      <aside class="bd-aside  text-muted align-self-start mb-3 mb-xl-5 px-2">
        <nav class="small" id="toc">
          <ul class="list-unstyled">
            <li class="my-2">
              <Link class="d-inline-flex align-items-center rounded" to={'/Home'}>
                <Button
                  onClick={onClose}
                  variant={'ghost'}
                  color={'#9f1d21'}
                  top={4}
                >
                  <BiHomeAlt color={'#9f1d21'} fontSize={20} />
                  <Text marginLeft={6}> Dashboard</Text>
                </Button>
              </Link>
            </li>
            <li class="my-2">
              <Customer />
            </li>
            <li class="my-2">
              <Campaign />
            </li>
            <li class="my-2">
              {type === 'em' ? '' : <Employe />}
            </li>
            <li class="my-2">
              {type === 'em' ? '' : <Setting />}
            </li>
            <li class="my-2">
              <Help />
            </li>
            <li class="my-2">
              <Buttonshow />
            </li>
            <br></br>

          </ul>
        </nav>

      </aside>






    </>
  );
};

export default Header;
