import { Button, Heading, Stack, Text, VStack, HStack } from '@chakra-ui/react';
import { Box, Image, SimpleGrid, Flex, collapse, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Navbar from './Navbar';
import '../Styles/main.css'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Container, Input, Center, Link
} from '@chakra-ui/react'
import { getSuggestedQuery } from '@testing-library/react';


import { BiSlider, BiEdit, BiTrash, BiBlock, BiCheckSquare } from 'react-icons/bi';
import secureLocalStorage from "react-secure-storage";

const CampaignList = () => {

  const [collapse, setCollapse] = React.useState(true);
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const type = secureLocalStorage.getItem("type");

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 25;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const nPage = Math.ceil(users.length / recordsPerPage);

  if (id) {

    axios.post('https://radhamadhavseva.com/api/post_comp_delete.php?del=' + id).then(result => {
      console.log(result.data)
      if (result.data === 'delete') {
        navigate('/CampaignList');
        window.location.reload(true);
      } else {
        alert(result.data)
        navigate('/CampaignList');
      }
    })

  }


  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/comp_view.php');
    setUsers(await response.json());
  }



  useEffect(() => {
    getUsers();
  }, []);


  function AbortCampaign(id) {
    axios.post('https://radhamadhavseva.com/api/abort_compaign.php?id=' + id).then(result => {
      if (result.data === 'abort') {
        navigate('/CampaignList');
        window.location.reload(true);
      } else {
        alert(result.data)
        navigate('/CampaignList');
      }
    })
  }


  function ActivateCampaign(id) {
    axios.post('https://radhamadhavseva.com/api/activate_compaign.php?id=' + id).then(result => {
      if (result.data === 'active') {
        navigate('/CampaignList');
        window.location.reload(true);
      } else {
        alert(result.data)
        navigate('/CampaignList');
      }
    })
  }
  // console.log(users.filter(user=>user.name.toLowerCase().includes("e")));

  function prePage() {

    if (currentPage !== firstIndex) {
      if (firstIndex === 0) {
        //alert('h')
      } else {
        setCurrentPage(currentPage - 1)
      }
    }

  }

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1)
    }

  }

  return (
    <>
      <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
            <div class="container-fluid">

              <Box
                overflowY="auto"

                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',

                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',

                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '24px',

                  }

                }}

              >
               


                <Heading
                    fontSize={'17px'}
                    backgroundColor='#9f1d21'
                    color={'white'}
                    p={2}

                  >
                    <div class="col-lg-6 col-sm-12 col-md-12">
                    <Input placeholder='Search' color={'black'}   onChange={(e) => setQuery(e.target.value)} border="2px" borderColor={"#9f1d21"} borderRadius={'105px'} backgroundColor={'white'} />
    

                    </div>
                  </Heading>


                <Stack direction={['column', 'row']} p={'0px'}  >




                  <TableContainer w={'full'} mt={2}>
                    <Table size='sm' variant='striped' colorScheme='gray' border='2px' borderColor='#9f1d21'>

                      <Thead backgroundColor={'#9f1d21'}>
                        <Tr>
                          <Th color={'white'}>S No.</Th>
                          <Th color={'white'}>Name</Th>
                          <Th color={'white'}>Date</Th>
                          <Th color={'white'}>Time Slot</Th>
                          <Th color={'white'}>Mode</Th>
                          <Th color={'white'}>Group</Th>
                          <Th color={'white'}>Templates</Th>
                          <Th color={'white'}>Status</Th>
                          <Th color={'white'}>Action</Th>

                        </Tr>
                      </Thead>
                      <Tbody>
                        {

                          users.filter(user =>
                            user.Campaign_name.toLowerCase().includes(query)
                            ||
                            user.dlt.includes(query)

                            ||

                            user.mode.includes(query)

                          ).slice(!query ? firstIndex : 0, !query ? lastIndex : 25).map((curElem, index) => {
                            return (

                              <Tr key={index}>
                                <Td>{index + 1}</Td>
                                <Td>{curElem.Campaign_name}</Td>

                                <Td>{curElem.date}</Td>
                                <Td>{curElem.time_slot}</Td>
                                <Td>{curElem.mode}</Td>
                                <Td>{curElem.customer_group}</Td>
                                <Td>{curElem.dlt.slice(0, 30)}<br />
                                  {curElem.dlt.slice(30, 60)}<br />
                                  {curElem.dlt.slice(60, 90)}<br />
                                  {curElem.dlt.slice(90, 120)}<br />
                                  {curElem.dlt.slice(120, 150)}<br />
                                  {curElem.dlt.slice(150, 180)}
                                </Td>
                                <Td>{curElem.status}</Td>
                                <Td>
                                  <HStack>
                                    {type === 'em' ? (
                                      ''
                                    ) : (
                                      <>
                                        <Link variant='solid' colorScheme='green' onClick={() => navigate(`/EditCampaign/${curElem.id}`)}>
                                          <BiEdit fontSize={20} />
                                        </Link>

                                        <Link variant='solid' colorScheme='green' onClick={() => navigate(`/CampaignList/${curElem.id}`)}>
                                          <BiTrash fontSize={20} />
                                        </Link>
                                      </>
                                    )}


                                    {curElem.status === '1' && (
                                      <Link variant='solid' colorScheme='green' onClick={() => AbortCampaign(curElem.id)}>
                                        <BiBlock fontSize={20} />
                                      </Link>
                                    )}

                                    {curElem.status === '0' && (
                                      <Link variant='solid' colorScheme='green' onClick={() => ActivateCampaign(curElem.id)}>
                                        <BiCheckSquare fontSize={20} />
                                      </Link>
                                    )}
                                  </HStack>
                                </Td>


                              </Tr>

                            )
                          })
                        }
                      </Tbody>

                    </Table>
                    <Box float={'right'} marginBottom={3} w={'162px'} mt={3}>

                      <Button backgroundColor={'#9f1d21'} marginLeft={5} variant={'ghost'} colorScheme={'white'} onClick={prePage} size={'sm'} color={'white'}>Prev</Button>
                      <Button backgroundColor={'#9f1d21'} size={'sm'} variant={'ghost'} colorScheme={'white'} marginLeft={5} onClick={nextPage} color={'white'}>Next</Button>



                    </Box>
                  </TableContainer>
                </Stack>





              </Box>


            </div>

          </div>
        </div>
      </div>


    </>
  );
};

export default CampaignList;
