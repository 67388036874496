import { Box, Container, Heading, Image, Stack, Textarea, Text, Input, SimpleGrid, Button,Flex,HStack,collapse,IconButton,Select, Hide } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter,Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { useNavigate,    useParams } from "react-router-dom";
import { BiSlider } from 'react-icons/bi';
import axios from 'axios';
import Navbar from './Navbar';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const EditCampaign = () => {
  const [resize, setResize] = React.useState('vertical')
  
  const [mode, setMod] = useState([]);
  const [collapse, setCollapse] = React.useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [a, setData_A] = useState([]);
  const [b, setData_B] = useState([]);
  const [c, setData_C] = useState([]);
  const [d, setData_D] = useState([]);
  const [e, setData_E] = useState([]);
  const [f, setData_F] = useState([]);
  const [idd, setData_ID] = useState([]);
  const [checkArray, setcheckArray] = useState([]);
  const [userss, setPosts] = useState([]);
  const apiEndPoint = 'https://radhamadhavseva.com/api/comp_view.php?id='+id;

  useEffect(() =>{
    const getPosts = async () =>{
        const {data: res} =  await axios.get(apiEndPoint);
        setPosts(res);
        setData_ID(res[0].id);
        setData_A(res[0].Campaign_name);
        setData_B(res[0].date);
        setData_C(res[0].time_slot);
        setData_D(res[0].mode);
        setData_E(res[0].customer_group);
        setData_F(res[0].dlt);
    };
    getPosts();
    
  }, []);
 
  
  const getUsers = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/group.php');
    setUsers(await response.json());
  }



  useEffect(() => {
   getUsers();
  },[]);

//   axios.post('https://radhamadhavseva.com/api/comp_view.php?id='+id).then(result => {
//     console.log(result.data)
//     const response = result.data
//     setUserss(result.data);
//     setData_ID(userss.map(curElem => curElem.id))
//     setData_A(userss.map(curElem => curElem.Campaign_name))
//     setData_B(userss.map(curElem => curElem.date))
//     setData_C(userss.map(curElem => curElem.time_slot))
//     setData_D(userss.map(curElem => curElem.mode))
//     setData_E(userss.map(curElem => curElem.customer_group))
//     setData_F(userss.map(curElem => curElem.dlt))
//     // const cHeck = result.data;
//     // if(cHeck === 'Campaign Successfully Scheduled'){
//     //   alert(result.data)
//     //   navigate("/CampaignList");
//     //   ///alert(result.data)
//     // }else{
//     //   alert(result.data)
//     // }
    
   
//   })
  
  
  
  
  
//   useEffect(() => {
//     setUserss();
//   },[]);
  
 
// var sentence = e;
// var numStr = sentence.split(" ");
// console.log((numStr));


  const [Name, setName] = useState('');
  const [Date, setDate] = useState('');
  const [Time, setTime] = useState('');
  const [Mode, setMode] = useState('');
  const [Group, setGroup] = useState('');
  const [Dlt, setDlt] = useState('');
  const [Status, setStatus] = useState('');
  const [com_id, setId] = useState('');
 
  const handelGroupp = (e) =>{
    const value = e.target.value;
    const checked = e.target.checked;

    console.log(value, checked);

    if(checked){
      setcheckArray([
        ...checkArray, value
      ])
      setData_E([
        ...checkArray, value
      ])
    }else{
      setcheckArray(checkArray.filter( (e) => (e !== value) ))
      setData_E(checkArray.filter( (e) => (e !== value) ))
    }
    //let tempUser = users.map( (user) => user.Customer_Group === namee ? {...user, isChecked : checked} : user);
   
  }

  const  handelId = (e) =>{
    setId(e.target.value)
  }

  const handelName = (e) =>{
    setName(e.target.value)
    setData_A(e.target.value)
  }

  const handelDate = (e) =>{
    setDate(e.target.value)
    setData_B(e.target.value)
  }

  const handelTime = (e) =>{
    setTime(e.target.value)
    setData_C(e.target.value)
  }

  const handelMode = (e) =>{
    setMode(e.target.value)
    setData_D(e.target.value)
  }

  const handelGroup = (e) =>{
    setGroup(e.target.value)
    setData_E(e.target.value)
  }

  const handelDlt = (e) =>{
    setDlt(e.target.value)
    setData_F(e.target.value)
  }






  const handleSubmitt = (event) => {
   

    event.preventDefault();
    let fData = new FormData();
    fData.append('Campaign_id', idd);
    fData.append('Campaign_name', a);
    fData.append('date', b);
    fData.append('time', c);
      fData.append('mode', d);
      fData.append('group', e);
      fData.append('dlt', f);
     

  axios.post('https://radhamadhavseva.com/api/post_comp.php?id=updt', fData)

  .then(result => {
    console.log(result.data)
    const cHeck = result.data;
    if(cHeck === 'update'){
      //alert(result.data)
      navigate("/CampaignList");
      ///alert(result.data)
    }else{
      //alert(result.data)
    }
   
   
  })

  .catch(error => {
    alert('not success')
    console.log(error)
  })

  };



  const getMode = async () => {
    const response = await fetch('https://radhamadhavseva.com/api/mode.php?fetch=fetch');
    setMod(await response.json());

  }

  useEffect(() => {
    getMode();
  }, []);


  return (
    <>
   
   <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

Edit Campaign</Heading>
            <div class="container-fluid  border">
              <div class="row justify-content-center">
    
              <form class="col-lg-8 mb-3" onSubmit={handleSubmitt}>
                  <SimpleGrid spacing={4} marginBottom={'40px'} >

                   
                  

<Hide>
<Input
                  placeholder={'Campaign name'}
                  type={'text'}
                 
                  onChange={handelId}
                  
                  top={2}
                  value=   {idd}
                  required
                  focusBorderColor={'#9f1d21'}
                />
                </Hide>
            
   <Input
                  placeholder={'Campaign name'}
                  type={'text'}
                 
                  onChange={handelName}
                  
                  top={2}
                  value=  {a}
                  required
                  focusBorderColor={'#9f1d21'}
                />
            
<Input
                  placeholder={'Date'}
                  type={'date'}
                 
                   onChange={handelDate}
                  
                  top={2}
                  value=  {b}
                  required
                  focusBorderColor={'#9f1d21'}
                />

<Input
                  placeholder={'Time Slot'}
                  type={'time'}
                 
                  onChange={handelTime}
                  
                  top={2}
                  value=  {c}
                  required
                  focusBorderColor={'#9f1d21'}
                />


<Select placeholder={d}
                    onChange={handelMode}
                    
                    top={2}
                    required
                    focusBorderColor={'#9f1d21'}>
                   <option value={d} selected>{d}</option>
                       {
                       
mode.map((curElem) => {
  return (
                    <option value={curElem.type}>{curElem.type}</option>
                  

                    )
                  })
                }
                  


                  </Select>







                      <HStack>
                       
 {
                      users.map((curElem, index) => {
                        return (
                       <>
<Checkbox colorScheme='green' value={curElem.Customer_Group}  onChange={handelGroupp}>
{curElem.Customer_Group}
  </Checkbox>
  </>
                    )})}     
                      </HStack>
                   


<Input
                  placeholder={'Customer Group'}
                  type={'text'}
                 
                  onChange={handelGroup}
                  
                  top={2}
                  value=  {e}
                  required
                  focusBorderColor={'#9f1d21'}
                />


<Textarea
                  placeholder={'DLT No.'}
                  type={'text'}
                 
                  onChange={handelDlt}
                  
                  top={2}
                  value=  {f}
                  required
                  focusBorderColor={'#9f1d21'}
                />




{/* <Select placeholder='Select Status' 
                  onChange={handelStatus}
                  
                  top={2}
                  required
                  focusBorderColor={'#9f1d21'}>
  <option value='Active'>Active</option>
  <option value='Not Active'>Not Active</option>
  
</Select> */}


      
      
      

                <Center>
                    <Button bg={'#9f1d21'} w={40} color={'white'} type={'submit'}>
                     Update
                    </Button>
                    </Center>
                         
</SimpleGrid>
                  </form>

</div>
</div>

</div>
</div>
</div>
    </>
  );
};


export default EditCampaign;
