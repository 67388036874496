import { Button, Container, HStack, Input, VStack,Stack,Text,Center, FormLabel } from '@chakra-ui/react';
import { Box, Heading, Image, SimpleGrid, Flex, collapse,IconButton } from '@chakra-ui/react';
import React, {useState} from 'react';
import Header from './Header';
import axios from 'axios';
import * as XLSX from "xlsx";
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiSlider } from 'react-icons/bi';
import { useNavigate } from "react-router-dom";
import Navbar from './Navbar';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { Spinner } from '@chakra-ui/react'
const Upload = () => {
  const [image_name_a, setFilename] = useState();
  const [items, setItems] = useState([]);
  const [clic, setClic] = useState([]);
  const navigate = useNavigate();
  const [alertt,  setAlertt] = useState();
  const [collapse, setCollapse] = React.useState(true);
  

  const [file, setFile] = useState();

  function handleChange(e) {
  
    console.log(e.target.files);
    setFilename(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }


  const handleSubmit = (event) => {
     setClic('clk');
    //Prevent page reload
    event.preventDefault();
     
    
    let fData = new FormData();
    
    fData.append('front', image_name_a);
   

    axios({
      method: 'post',
      url: 'https://radhamadhavseva.com/api/uplod.php',
      data: fData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        setClic('');
        if (cHeck === 'Registeration successfully') {

         // navigate('/Home');
         //alert(result.data)

        } else {
         // alert(result.data)
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });

  };


  return (
    <>
     <Navbar />
     <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

                     Bulk Upload</Heading>
            <div class="container-fluid border">
              <div class="row">
                
               
 
 
      {alertt==='Data Saved Succesfully' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='success'>
          <AlertIcon />
          Data Successfully uploaded
        </Alert>
      </Stack>
      }


      {alertt==='Data not Saved' &&
        <Stack spacing={3}>
        <Alert status='error'>
          <AlertIcon />
          Data  not uploaded Error.!
        </Alert>
      </Stack>
      }
      
     
      <VStack color={'#9f1d21'} h={'full'} marginBottom={'250px'} justifyContent={'center'}>
       
        <AiOutlineCloudUpload size={'10vmax'} />
       
        <form onSubmit={handleSubmit}>
         
            <Box>
                <FormLabel fontWeight={'bold'}>Please upload excel file .csv</FormLabel>
                <Input type="file" onChange={handleChange}  required />
                
              </Box>
         <Button bg={'#9f1d21'} color={'white'} type={'submit'} mt={4}>
                Submit
              </Button>
              {clic==='clk' ? (
        <Spinner />
      ) : ( 

''
      )}
          
         
         
        </form>
       
       

    

        {/* <table class="table container">
        <thead>
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {items.map((d) => (
            <tr>
              <th>{d.Item}</th>
              <td>{d.Description}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
      </VStack>

               
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default Upload;
