import { Box, Container, Heading, Image, Stack, Text, SimpleGrid, Button, Flex, HStack, collapse, IconButton, VStack, Input, Hide } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from './Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Card, CardHeader, CardBody, CardFooter, Center } from '@chakra-ui/react'
import { Link, useNavigate,  useParams } from 'react-router-dom';
import { BiSlider } from 'react-icons/bi';
import Navbar from './Navbar';
import axios from 'axios';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'


const headingOptions = {
  pos: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  textTransform: 'uppercase',
  p: '4',
  size: '4xl',
};

const  EditEmploye = () => {

  const [users, setUsers] = useState([]);
  const [collapse, setCollapse] = React.useState(true);

  const [email, setEmail] = useState([]);
  const [pass, setPassword] = useState([]);
  const [name, setName] = useState([]);
  const [desig, setDesig] = useState([]);
  const [dob, setDob] = useState([]);
  const [eid, setEid] = useState([]);

  const [alertt,  setAlertt] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  const [a, setData_A] = useState([]);
  const [b, setData_B] = useState([]);
  const [c, setData_C] = useState([]);
  const [e, setData_E] = useState([]);
  const [f, setData_F] = useState([]);
  const [idd, setData_ID] = useState([]);

  const [userss, setPosts] = useState([]);
  const apiEndPoint = 'https://radhamadhavseva.com/api/edit_employe_view.php?id='+id;

  useEffect(() =>{
    const getPosts = async () =>{
        const {data: res} =  await axios.get(apiEndPoint);
        setPosts(res);
        setData_ID(res[0].id);
        setData_A(res[0].name);
        setData_B(res[0].eid);
        setData_C(res[0].designation);
        setData_E(res[0].email);
        setData_F(res[0].pass);
    };
    getPosts();
    
  }, []);
 









  const handelName = (e) => {
    setName(e.target.value)
    setData_A(e.target.value)
  }

  const handelEid = (e) => {
    setEid(e.target.value)
    setData_B(e.target.value)
  }

  const handelDesig = (e) => {
    setDesig(e.target.value)
    setData_C(e.target.value)
  }

  


  const handelEmail = (e) => {
    setEmail(e.target.value)
    setData_E(e.target.value)
  }

  const handelPass = (e) => {
    setPassword(e.target.value)
    setData_F(e.target.value)
  }


  const handleSubmit = (event) => {
    
    //Prevent page reload
    event.preventDefault();
    let fData = new FormData();
    fData.append('id', idd);
    fData.append('name', a);
    fData.append('eid', b);
    fData.append('desig', c);
    fData.append('email', e);
    fData.append('pass', f);
   

    axios.post('https://radhamadhavseva.com/api/employe_updt.php', fData)

      .then(result => {
        console.log(result.data)
        const cHeck = result.data;
        setAlertt(result.data);
        if (cHeck === 'yes') {

          setTimeout(() => {
            
                navigate("/EmployeList");
            
          }, 1000);
      
          ///alert(result.data)
        } else {
         // alert(result.data)
        }


      })

      .catch(error => {
        alert('not success')
        console.log(error)
      })

  };




  return (
    <>
     <Navbar />
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2 d-md-none d-lg-block   d-sm-none d-md-block d-none d-sm-block bg-white">


            <div class='sticky-left position-fixed  sidebar'>

              <Header />


            </div>

          </div>
          <div class="col-lg-10 top-0  end-0">
          <Heading
                      fontSize={'17px'}
                      backgroundColor='#9f1d21'
                      color={'white'}
                      p={2}

                    >

Edit Employe</Heading>
            <div class="container-fluid  border">
              <div class="row justify-content-center">
    

  {alertt==='yes' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='success'>
          <AlertIcon />
          Employe Successfully Updated
        </Alert>
      </Stack>
      }

{alertt==='no' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='error'>
          <AlertIcon />
          Employe Not saved !
        </Alert>
      </Stack>
      }

{alertt==='Employe Already saved' &&
        <Stack spacing={3} mt={'40px'} >
        <Alert status='error'>
          <AlertIcon />
          Employe Already saved !
        </Alert>
      </Stack>
      }

          
  <form class="col-lg-8 mb-3 mt-3" onSubmit={handleSubmit}>
                  <SimpleGrid spacing={4} marginBottom={'40px'}>

                   
             
  <Input
                  placeholder={'Employee Name'}
                  type={'text'}
                  h={9}
                  onChange={handelName}
                  value=   {a}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  
  <Input
                  placeholder={'Employee id'}
                  type={'text'}
                  h={9}
                  onChange={handelEid}
                  value=   {b}
                  required
                  focusBorderColor={'#9f1d21'}
                />
 
  <Input
                  placeholder={'Designation'}
                  type={'text'}
                  h={9}
                  onChange={handelDesig}
                  value=   {c}
                  required
                  focusBorderColor={'#9f1d21'}
                />
 


  
  <Input
                  placeholder={'Email'}
                  type={'email'}
                  h={9}
                  onChange={handelEmail}
                  value=   {e}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  
  
  <Input
                  placeholder={'Password'}
                  type={'text'}
                  h={9}
                  onChange={handelPass}
                  value=   {f}
                  required
                  focusBorderColor={'#9f1d21'}
                />
  
  <Center>
                    <Button bg={'#9f1d21'} w={40} color={'white'} type={'submit'}>
                      Update
                    </Button>
                    </Center>
 
  
                
</SimpleGrid>
                  </form>

</div>
</div>

</div>
</div>
</div>
      
    </>
  );
};


export default  EditEmploye;
